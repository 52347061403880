<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    data() {
        return {
            toastIds: [],
        };
    },
    created() {
        this.$GlobalEventEmitter.$on('makeToast', this.makeToast);
    },
    beforeDestroy() {
        this.$GlobalEventEmitter.$off('makeToast');
    },
    methods: {
        makeToast(payload) {
            const { variant, msg } = payload;
            const h = this.$createElement;

            const toastId = uuidv4();
            // Create the message
            const vNodesMsg = [
                h(
                    'div',
                    {
                        class: 'm-0 d-flex justify-content-between align-items-center',
                    },
                    [
                        h('div', null, [msg]),
                        h(
                            'div',
                            {
                                class: 'hover:pointer',
                                attrs: {
                                    role: 'button',
                                },
                                on: {
                                    click: () => {
                                        this.closeToast(toastId);
                                    },
                                },
                            },
                            [h('close-icon')],
                        ),
                    ],
                ),
            ];

            // Pass the VNodes as an array for message and title
            this.$bvToast.toast([vNodesMsg], {
                id: toastId,
                variant,
                noCloseButton: true,
                toaster: 'b-toaster-top-center',
                solid: true,
                autoHideDelay: 2500,
            });
        },
        closeToast(toastId) {
            // Close the target toast
            this.$bvToast.hide(toastId);
        },
    },
};
</script>
<style>
.b-toaster.b-toaster-top-center {
    top: 40px !important;
}
.b-toast,
.b-toaster .b-toaster-slot {
    max-width: 60% !important;
}
.b-toast {
    width: 100%;
    max-width: 100% !important;
}
.b-toast .toast {
    background-color: transparent;
    border: 0;
    border-radius: 40px;
    max-width: 100%;
    width: 100%;
}
.toast-body {
    padding: 12px 16px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #fff;
    min-height: 48px;
    border-radius: 40px;
}
.b-toast-danger .toast-body {
    background: #ed503e;
}
.b-toast-success .toast-body {
    background: #187967;
}
.b-toast-warning .toast-body {
    background: #ffac2f;
    color: #121212;
}
@media only screen and (max-width: 768px) {
    .b-toast,
    .b-toaster .b-toaster-slot {
        max-width: 100% !important;
    }
    .b-toaster.b-toaster-top-center {
        top: 45px !important;
    }
}
</style>
